
    @use '@variables_common' as *;
    
@use '@variables_iqfu' as *;

.container {
  padding-top: 48px;
  border-top: 1px solid #ccc;

  @include media(lg) {
    padding-top: 32px;
  }
}

.top_column {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  padding: 0 20px 40px;
  margin: 0 auto;
  border-bottom: 1px solid #ccc;

  @include media(lg) {
    flex-direction: column;
    padding: 0;
    margin-right: 20px;
    margin-left: 20px;
  }
}

.logo_wrapper {
  @include media(lg) {
    display: block;
    margin: 0 auto 20px;
  }
}

.nav {
  @include media(lg) {
    margin-bottom: 20px;
  }
}

.nav_top {
  margin-bottom: 16px;
  font-weight: bold;
  line-height: 1;
  color: #333;

  @include media(lg) {
    padding-top: 20px;
    text-align: center;
    border-top: solid 1px #ccc;
  }
}

@mixin nav_item {
  position: relative;
  display: flex;
  gap: 0 4px;
  align-items: center;
  font-size: min(1.4rem, 18px);
  color: #666;

  &::before {
    display: inline-block;
    width: 5px;
    height: 1px;
    content: '';
    background: #999;
  }

  @include media(lg) {
    gap: 0 3px;
  }
}

.footer_nav_list_stacks_desktop {
  display: flex;
  gap: 0 12px;

  @include media(lg) {
    flex-direction: column;
    gap: 8px 0;
  }
}

.nav_list {
  @include media(lg) {
    display: flex;
    flex-direction: column;
    gap: 8px 12px;

    &_horizontal {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

.nav_item {
  @include nav_item;

  margin-bottom: 12px;

  a:hover {
    text-decoration: underline;
  }

  @include media(lg) {
    margin-bottom: 0;
  }
}

.line_link_button {
  display: block;
  padding: 8px 40px;
  margin-top: 16px;
  color: #fff;
  background: #12cb46;

  @include hover_color($public_line_green_hover);

  border-radius: 6px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

  @include media(lg) {
    display: table;
    padding: 10px 40px;
    margin-right: auto;
    margin-left: auto;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.1em;
  }
}

.second_column {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  padding: 40px 20px;
  margin: 0 auto;

  @include media(lg) {
    flex-direction: column;
    padding: 32px 0;
    margin-right: 20px;
    margin-left: 20px;
  }
}

.media_nav_head {
  flex-shrink: 0;
  width: 162px;
  margin-right: 48px;
  font-weight: bold;

  @include media(lg) {
    margin-right: 0;
    margin-bottom: 16px;
    line-height: 23px;
  }
}

.media_nav_list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  @include media(lg) {
    gap: 10px;
  }
}

.media_nav_item {
  @include nav_item;

  a:hover {
    text-decoration: underline;
  }

  @include media(lg) {
    line-height: 20px;
  }
}

.media_nav_top {
  margin-bottom: 8px;
  font-weight: bold;
  line-height: 1;
  color: #333;

  @include media(lg) {
    font-size: 1.4rem;
    line-height: 20px;
  }
}

.media_nav_wrapper:not(:last-of-type) {
  margin-bottom: 32px;

  @include media(lg) {
    margin-bottom: 20px;
  }
}

.bottom_link_list {
  display: flex;
  gap: 0 10px;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background: #f9f9f9;

  @include media(lg) {
    flex-direction: column;
    gap: 4px 0;
    padding: 10px 24px;

    .bottom_link_item {
      font-size: 1.2rem;
    }
  }
}

.bottom_link_item_divider {
  display: block;
  width: 1px;
  height: 20px;
  margin: 0 10px;
  text-align: center;
  background: #ccc;

  @include media(lg) {
    height: 16px;

    &.desktop_only {
      display: none;
    }
  }
}

.bottom_link_stacks {
  display: flex;
  gap: 0 10px;
  align-items: center;
  line-height: 1;

  @include media(lg) {
    gap: 0;
    justify-content: center;
    text-align: center;
  }
}

.bottom_link_item {
  flex-shrink: 0;
  text-align: center;
}

.bottom_link_item,
.bottom_link_item_divider {
  line-height: 28px;
}

.copyRight {
  padding: 20px;
  text-align: center;

  small {
    font-size: 1.2rem;
    color: #666;
  }

  @include media(lg) {
    padding: 26px;
  }
}

.footer_seo_h1 {
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto 24px;
  font-size: 1.5rem;
  color: #666;
}

.top_page_section {
  @include media(lg) {
    margin-bottom: 20px;
  }
}
