
    @use '@variables_common' as *;
    
@use '@variables_iqfu' as *;

.logo {
  display: block;
  width: 270px;
  margin: 88px auto 64px;

  @include media(sm) {
    max-width: 60%;
    margin: 40px auto 20px;
  }
}

.title {
  margin-bottom: 16px;
  font-size: 14rem;
  font-weight: 900;
  line-height: 1;
  color: #555;
  text-align: center;

  @include media(sm) {
    font-size: 9rem;
  }
}

.title_sub {
  margin-bottom: 24px;
  font-size: 3.4rem;
  font-weight: 900;
  line-height: 1;
  color: #999;
  text-align: center;
  letter-spacing: 0.05em;

  @include media(lg) {
    font-size: 2.8rem;
  }
}

.text_wrapper {
  display: flex;
  margin: 0 auto;
  margin-bottom: 24px;
}

.ikuko {
  margin-right: 24px;
  margin-left: auto;

  @include media(sm) {
    display: none;
  }
}

.error_text {
  margin: auto 0;
  margin-right: auto;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 29px;
  color: #555;

  @include media(sm) {
    margin: auto;
    font-size: 1.4rem;
    text-align: center;
  }
}

.top_link {
  display: block;
  align-items: center;
  width: 270px;
  padding: 16px;
  margin: 40px auto 80px;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  letter-spacing: 0.1em;
  cursor: pointer;
  background: $public_button_green;
  border-radius: 90px;

  @include media(lg) {
    margin: 16px auto 40px;
  }

  @include hover_color($public_button_hover_green);
}
